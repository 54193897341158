import React from 'react';
import { Link, graphql } from 'gatsby';
import HeroLayout from '../components/Layouts/HeroLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import WorkDetailSection from '../components/WorkDetailSection/WorkDetailSection';
import Footer from '../components/Footer/Footer';

class WorkDetailTemplate extends React.Component {
  render() {
    const workData = this.props.data.markdownRemark.frontmatter;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <HeroLayout metaTitle={workData.title} metaDescription={workData.description}>
        <Scrollbars style={{ height: '100vh', width: '100%' }}>
          <WorkDetailSection data={workData} />
          <Footer />
        </Scrollbars>
      </HeroLayout>
    );
  }
}

export default WorkDetailTemplate;

export const pageQuery = graphql`
  query WorksBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        primeTags
        responsibilities
        linkOneUrl
        linkOneLabel
        linkTwoUrl
        linkTwoLabel
        techUsed {
          name
          techName
        }
      }
    }
  }
`;
