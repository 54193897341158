import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import EdgeLink from '../EdgeLink';
import TooltipButton from '../TooltipButton';
const WorkDetailSection = (props) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    
  }, []);

  return (
    <section id="work" className="jumbotron">
      <Container className="px-0">
        <Fade bottom duration={600} delay={600} distance="30px">
          <div className="bullet-tags">
            <span>{props?.data?.primeTags[0]}</span>&emsp; &bull; &emsp;
            <span>{props?.data?.primeTags[1]}</span>
          
            {props?.data?.primeTags.length > 2 && isDesktop && <span>&emsp; &bull; &emsp;{props?.data?.primeTags[2]}</span>}
          </div>
        </Fade>
        <Fade bottom duration={1000} delay={1000} distance="10px">
          <div className="work-title mt-4">{props.data.title}</div>
        </Fade>

        <Fade bottom duration={1000} delay={1300} distance="10px">
          <div className="work-desc mt-3">{props.data.description}</div>
        </Fade>

        <div className="tech-used-block mt-5">
          <Fade bottom duration={800} delay={1500} distance="10px">
            <h5 className="title">TECH USED</h5>
          </Fade>

          <div className="tech-items mt-4">
            {props.data.techUsed &&
              props.data.techUsed.map((tech, idx) => (
                <Zoom key={idx} duration={500} delay={1500 + 100 * idx}>
                  <TooltipButton svgImg={`${tech.name}Icon`} techName={tech.techName} />
                </Zoom>
              ))}
          </div>
        </div>

        <div className="responsibilities-block mt-5 pt-3">
          <Fade bottom duration={800} delay={1800} distance="10px">
            <h5 className="title">RESPONSIBILITIES</h5>
          </Fade>

          <div className="resp-list mt-4">
            <ul>
              {props.data.responsibilities &&
                props.data.responsibilities.map((res, idx) => (
                  <Fade
                    key={idx}
                    bottom
                    duration={800}
                    delay={isMobile ? 100 + idx * 100 : 2100 + idx * 200}
                    distance="10px"
                  >
                    <li>{res}</li>
                  </Fade>
                ))}
            </ul>
          </div>

          <div className="work-links-block mt-5 py-5">
            <div className="wrapper">
              <Fade bottom duration={2000} delay={isMobile ? 500 : 1500} distance="10px">
                {props.data.linkOneUrl && <EdgeLink text={props.data.linkOneLabel} hasHref={props.data.linkOneUrl}/>}                
              </Fade>

           { props.data.linkTwoUrl &&  <Fade bottom duration={2000} delay={isMobile ? 1000 : 2000} distance="10px">
                <EdgeLink text={props.data.linkTwoLabel} hasHref={props.data.linkTwoUrl} />
              </Fade>}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WorkDetailSection;
