import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  ReactIcon,
  SassIcon,
  NextIcon,
  RorIcon,
  MaterialIcon,
  AntdIcon,
  PusherIcon,
  TailwindIcon,
  ReduxTwoIcon,
} from '../SvgComponents/workIcons';

function TooltipButton(props) {
  const [icon, setIcon] = useState(null);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    switch (props.svgImg) {
      case 'ReactIcon':
        setIcon(ReactIcon);
        break;
      case 'SassIcon':
        setIcon(SassIcon);
        break;
      case 'NextIcon':
        setIcon(NextIcon);
        break;
      case 'RorIcon':
        setIcon(RorIcon);
        break;
      case 'MaterialIcon':
        setIcon(MaterialIcon);
        break;
      case 'AntIcon':
        setIcon(AntdIcon);
        break;
      case 'PusherIcon':
        setIcon(PusherIcon);
        break;
      case 'TailwindIcon':
        setIcon(TailwindIcon);
        break;
      case 'ReduxIcon':
        setIcon(ReduxTwoIcon);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div
        className="tooltip-btn"
        data-tip={props.techName}
        data-for={`tech-btn-${props.techName}`}
      >
        {icon}
      </div>
      {!isMobile && (
        <ReactTooltip
          globalEventOff={isMobile ? 'touchstart' : undefined}
          eventOff="touch"
          id={`tech-btn-${props.techName}`}
          type="dark"
          effect="solid"
          place="bottom"
          delayShow={500}
          className="omega-under-tip"
          isCapture={true}
        />
      )}
    </>
  );
}

export default TooltipButton;
